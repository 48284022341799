<template>
  <div class="app-container">
    <transition :name="subRouteOutput" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SystemAdmin',
  data () {
    return {
      subRouteOutput: 'route-output'
    }
  }
}
</script>

<style lang="scss">
  .route-output-enter-active, .route-output-leave-active {
    transition: opacity .3s ease;
  }
  .route-output-enter, .route-output-leave-to {
    opacity: 0;
  }
</style>
